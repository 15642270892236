import React from 'react';
import PropTypes from 'prop-types';
import _map from 'lodash/map';
import SectionHeader from '../SectionHeader';

import styles from './schoolOverview.module.scss';

const classData = [
  {
    className: '3-4 year old class',
    time: 'Mon/Wed - 9am-11am',
    cost: '$90/month',
  },
  {
    className: '4-5 year old class',
    time: 'Tue/Thu - 9am-11am',
    cost: '$90/month',
  },
];

const ClassDetails = ({ className, time, cost }) => (
  <div className={styles.detailWrapper}>
    <p className={styles.name}>{className}</p>
    <p>{time}</p>
    <p>{cost}</p>
    <div className={styles.space} />
    <p className={styles.max}>Maximum 8 students</p>
  </div>
);

const SchoolOverview = () => (
  <div className={styles.overviewWrapper}>
    <SectionHeader title="Class Schedule" />
    <div className={styles.classesWrapper}>
      {_map(classData, ({ className, time, cost }, index) => (
        <ClassDetails key={`className_${index}`} className={className} time={time} cost={cost} />
      ))}
    </div>
    <div className={styles.imagesContainer}>
      <div className={styles.singleImageWrap}>
        <img
          src="https://res.cloudinary.com/dewd4pral/image/upload/v1584822540/lilypad/classroom_main_03_2020.png"
          alt="Preschool classroom in Vineyard, UT | Lilypad Preschool"
        />
      </div>

      <div className={styles.singleImageWrap}>
        <img
          src="https://res.cloudinary.com/dewd4pral/image/upload/v1591493429/lilypad/lily_pad_play_area_5_2020.png"
          alt="Preschool play area in Vineyard, UT | Lilypad Preschool"
        />
      </div>
    </div>
  </div>
);

ClassDetails.propTypes = {
  className: PropTypes.string.isRequired,
  time: PropTypes.string.isRequired,
  cost: PropTypes.string.isRequired,
};

export default SchoolOverview;
