import React from 'react';
import HelmetInfo from '../HelmetInfo';
import TopBar from '../TopBar';
import Jumbotron from '../Jumbotron';
import Bio from '../Bio';
import Philosophy from '../Philosophy';
import SchoolOverview from '../SchoolOverview';
import Footer from '../Footer';

import styles from './app.module.scss';

const App = () => (
  <div className={styles.appWrapper}>
    <HelmetInfo />
    <TopBar />
    <div>
      <Jumbotron />
      <Bio />
      <Philosophy />
      <SchoolOverview />
    </div>
    <Footer />
  </div>
);

export default App;
