import React from 'react';
import _map from 'lodash/map';
import SectionHeader from '../SectionHeader';
import Card from '../Card';

import styles from './philosophy.module.scss';

const data = [
  {
    name: 'Learn',
    icon: 'https://res.cloudinary.com/dewd4pral/image/upload/v1547688357/lilypad/learn.png',
    alt: 'Learn at Lilypad Preschool',
    text:
      'Students will prepare for kindergarten by learning letter recognition and sounds, number recognition, counting, colors, shapes, and learning about their world through books, activities, music, and\u00A0art.',
  },
  {
    name: 'Play',
    icon: 'https://res.cloudinary.com/dewd4pral/image/upload/v1547689661/lilypad/play.png',
    alt: 'Play while learning at Lilypad Preschool',
    text:
      'I believe kids learn important social skills through free play, including toys, games, and physical activity. This is where they establish the foundations of sharing and interpersonal relationships.',
  },
  {
    name: 'Grow',
    icon: 'https://res.cloudinary.com/dewd4pral/image/upload/v1547689736/lilypad/grow.png',
    alt: 'Grow at Lilypad Preschool',
    text:
      'Growing and developing fine motor skills is essential during the preschool years. Students will learn how to properly grip writing utensils and hold/utilize scissors to strengthen and develop these fine\u00A0motor\u00A0skills.',
  },
];

const Philosophy = () => {
  const cards = _map(data, ({ name, icon, alt, text }) => (
    <Card key={name} name={name} icon={icon} alt={alt} text={text} />
  ));

  return (
    <div className={styles.philosophyWrapper}>
      <SectionHeader title="Teaching Philosophy" />
      <div className={styles.cards}>{cards}</div>
    </div>
  );
};

export default Philosophy;
