import React from 'react';
// import { OutboundLink } from 'gatsby-plugin-google-analytics';
import styles from './topBar.module.scss';

const TopBar = () => (
  <header className={styles.topBarWrapper}>
    <div className={styles.logoContainer}>
      <img
        src="https://res.cloudinary.com/dewd4pral/image/upload/v1547521151/lilypad/lp-header-logo.png"
        alt="Lilypad Preschool in Vineyard, UT"
      />
    </div>
    <div className={styles.navContainer}>
      {/* <OutboundLink
        href="https://forms.gle/Vg2mLUQyEW8PnLFZ7"
        target="_blank"
        rel="noopener noreferrer"
      >
        <div className={styles.actionButton}>ENROLL NOW</div>
      </OutboundLink> */}
    </div>
  </header>
);

export default TopBar;
