import React from 'react';
import PropTypes from 'prop-types';
import styles from './sectionHeader.module.scss';

const SectionHeader = ({ title }) => (
  <div className={styles.sectionHeaderWrapper}>
    <h2>{title}</h2>
  </div>
);

SectionHeader.propTypes = {
  title: PropTypes.string.isRequired,
};

export default SectionHeader;
