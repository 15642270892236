import React from 'react';
import SectionHeader from '../SectionHeader';

import styles from './bio.module.scss';

const Bio = () => (
  <div className={styles.bioWrapper}>
    <SectionHeader title="About the Teacher" />
    <div className={styles.bioContent}>
      <div className={styles.avatar}>
        <img
          src="https://res.cloudinary.com/dewd4pral/image/upload/v1547612617/lilypad/emily_avi.jpg"
          alt="Emily at Lilypad Preschool"
        />
      </div>
      <div className={styles.textBox}>
        <p>
          My name is Emily Hagen and I love teaching preschool! I&#39;m passionate about working
          with kids and look forward to getting to know your student and giving them a solid
          foundation for their school&nbsp;years.
        </p>
        <br />
        <p>
          I have a degree from BYU in Marriage, Family, and Human Development with an emphasis on
          Child and Infant Development. I have experience working in daycare, as a nanny, with my
          own four children (two diagnosed with autism), and teaching primary kids in the 3-5 year
          old age range at&nbsp;my&nbsp;church.
        </p>
      </div>
      <div className={styles.singleImageWrap}>
        <img
          src="https://res.cloudinary.com/dewd4pral/image/upload/v1547964875/lilypad/trythis-compressor.jpg"
          alt="Family"
        />
      </div>
    </div>
  </div>
);

export default Bio;
