import React from 'react';
import { FaRegEnvelope, FaPhone, FaHome } from 'react-icons/fa';

import styles from './footer.module.scss';

const Footer = () => (
  <footer className={styles.footerWrapper}>
    <div className={styles.mainInfo}>
      <div className={styles.logoWrap}>
        <img
          src="https://res.cloudinary.com/dewd4pral/image/upload/v1547521151/lilypad/lp-header-logo.png"
          alt="Lilypad Preschool in Vineyard, Utah"
        />
      </div>

      <div className={styles.contactInfoWrapper}>
        <div className={styles.row}>
          <FaRegEnvelope size={20} />
          <p>
            <span className={styles.email}>lilypadpreschoolutah@gmail.com</span>
          </p>
        </div>
        <div className={styles.row}>
          <FaPhone size={17} />
          <p>(801) 357-9616</p>
        </div>
        <div className={styles.row}>
          <FaHome size={20} />
          <p>44 North 350 East Vineyard, UT</p>
        </div>
      </div>
    </div>

    <div className={styles.doodleWrap}>
      <img
        src="https://res.cloudinary.com/dewd4pral/image/upload/v1547939152/lilypad/kids-doodle-2.png"
        alt="Preschool kids"
      />
    </div>
  </footer>
);

export default Footer;
