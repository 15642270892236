import React from 'react';
// import { OutboundLink } from 'gatsby-plugin-google-analytics';

import styles from './jumbotron.module.scss';

const Jumbotron = () => (
  <div className={styles.wrapper}>
    <div className={styles.content}>
      <h1>
        <span>Lilypad </span>
        Preschool
      </h1>
      <h3>
        Welcome to Lilypad Preschool in Vineyard, Utah! Let&#39;s learn, play, and grow together.
        <span> We are not currently enrolling&nbsp;students.</span>
      </h3>
      <div className={styles.subContainer}>
        <div className={styles.actionButton}>ENROLL NOW</div>
        <div className={styles.imageWrapper}>
          <img
            src="https://res.cloudinary.com/dewd4pral/image/upload/v1547784148/lilypad/kids-doodle.png"
            alt="Vineyard Utah Preschool"
          />
        </div>
      </div>
    </div>
  </div>
);

export default Jumbotron;
