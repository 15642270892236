import React from 'react';
import PropTypes from 'prop-types';

import styles from './card.module.scss';

const Card = ({ name, icon, alt, text }) => (
  <div className={styles.cardWrapper}>
    <img src={icon} alt={alt} />
    <h3>{name}</h3>
    <p>{text}</p>
  </div>
);

Card.propTypes = {
  name: PropTypes.string.isRequired,
  icon: PropTypes.string.isRequired,
  alt: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
};

export default Card;
